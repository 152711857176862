@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/buttons';

html {
	min-height: 100vh;
}

body {
	background: transparent linear-gradient(180deg, #232426 0%, #4C4C58 100%) 0% 0% no-repeat padding-box;
}

.player-problem {
	display: none;
	background-color: white;
	color: black;
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	padding: 1rem;
	box-shadow: 0px 10px 50px rgba(0,0,0,.6);
	z-index: 800;

	a {
		color: lighten(black, 15%);
	}

	&.hidden {
		display: none;
	}
}

.slient-player {
	background: transparent url('../img/waves.png') 50% 50% no-repeat padding-box;
	text-align: center;

	.silent-player-container {
		width: 80%;
		margin: 0 auto;
	}

	.silent-logo {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.sponsors {
		margin: 1.5rem 0;

		& > div {
			margin-bottom: 1rem;
		}
	}
}

.player-overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	z-index: 900;
	color: #fff;

	& > div {
		background: rgba(68, 69, 79, 0.9);
		border-radius: 2px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		height: 180px;
		width: 180px;
		padding: 30px;
		margin: 1rem;
		position: relative;
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

		button {
			margin-top: 1.2rem;
		}
	}
}

.video-holder {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	background: transparent;
	text-align: center;

	.video {
		position: relative;
		min-height: 100px;
		min-width: 100px;
		order: 1;
		width: 40%;
		padding: 10px;

		&.active {
			width: 100%;
			order: -1;
		}
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
	}

	.change-channel {
		display: none;
		background-image: url('../img/arrow.svg');
		background-position: center center;
		position: absolute;
		background-repeat: no-repeat;
		background-size: contain;
		right: 100%;
		width: 40%;
		height: 100%;
	}

	&.video-active-green,
	&.video-active-blue {
		.video[data-color="red"] .change-channel {
			display: block;
		}
	}

	&.video-active-red {
		.video[data-color="green"] .change-channel {
			display: block;
		}
	}
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.control-bar {
	display: flex;
	align-items: center;
	color: #fff;
	padding: 5px;

	&.control-bar-red {
		background: #b93434;
	}
	&.control-bar-green {
		background: #51cb5a;
	}
	&.control-bar-blue {
		background: #3469e2;
	}

	& > div {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	& > div:first-child {
		justify-content: flex-start;
	}
	& > div:last-child {
		justify-content: flex-end;
	}

	.channel-title {
		flex-grow: 2;
		overflow: hidden;
		white-space: nowrap;
		text-transform: uppercase;
		font-size: .8rem;
	}

	.volume i {
		margin-right: 0.25rem;
	}

	.full-screen {
		margin-left: 0.25rem;
	}

	.volume-toggle,
	.full-screen {
		color: white;
		text-decoration: none;

		i {
			vertical-align: middle;
		}
	}

	.volume,
	.full-screen {
		display: none;
	}

	.video.active & {
		.volume,
		.full-screen {
			display: inline-flex;
		}
	}

	.control-right {
		white-space: nowrap;
	}
}

@media only screen and (max-width: 600px) {
	.slient-player .silent-player-container {
		width: 100%;
	}

	.video.active {
		margin-bottom: 80px;

		.change-channel {
			display: block;
			top: calc(100% + 10px);
			left: 50%;
			transform: translateX(-50%);
			right: auto;
			width: 100px;
			height: 61px;
			background-image: url('../img/arrow2.svg');
		}
	}

	.channel-title,
	.percentage {
		font-size: .8rem;
	}

	.video-holder .video {
		padding: 0;
		width: 50%;

		&:not(.active) {

			.control-left {
				display: none;
			}
			.channel-title {
				justify-content: start;
			}
		}
	}

	.volume-range {
		display: none;
	}
}

input[type=range] {
	margin: 4px 0;
	background-color: transparent;
	-webkit-appearance: none;

	&:focus {
		outline: none;
	}
	&::-webkit-slider-runnable-track {
		background: #ffffff;
		border: 0;
		border-radius: 11.9px;

		height: 7px;
		cursor: pointer;
	}
	&::-webkit-slider-thumb {
		margin-top: -4px;
		width: 15px;
		height: 15px;
		background: #ffffff;
		border: 2px solid rgba(0, 0, 0, 0.4);
		border-radius: 12px;
		cursor: pointer;
		-webkit-appearance: none;
	}
	&:focus::-webkit-slider-runnable-track {
		background: #ffffff;
	}
	&::-moz-range-track {
		background: #ffffff;
		border: 0;
		border-radius: 11.9px;

		height: 7px;
		cursor: pointer;
	}
	&::-moz-range-thumb {
		width: 15px;
		height: 15px;
		background: #ffffff;
		border: 2px solid rgba(0, 0, 0, 0.4);
		border-radius: 12px;
		cursor: pointer;
	}
	&::-ms-track {
		background: transparent;
		border-color: transparent;
		border-width: 4px 0;
		color: transparent;

		height: 7px;
		cursor: pointer;
	}
	&::-ms-fill-lower {
		background: #f2f2f2;
		border: 0;
		border-radius: 23.8px;
	}
	&::-ms-fill-upper {
		background: #ffffff;
		border: 0;
		border-radius: 23.8px;
	}
	&::-ms-thumb {
		width: 15px;
		height: 15px;
		background: #ffffff;
		border: 2px solid rgba(0, 0, 0, 0.4);
		border-radius: 12px;
		cursor: pointer;
		margin-top: 0px;
		// Needed to keep the Edge thumb centred
	}
	&:focus::-ms-fill-lower {
		background: #ffffff;
	}
	&:focus::-ms-fill-upper {
		background: #ffffff;
	}
}

// TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
// how to remove the virtical space around the range input in IE
@supports (-ms-ime-align:auto) {
	// Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589
	input[type=range] {
		margin: 0;
		// Edge starts the margin from the thumb, not the track as other browsers do
	}
}
